import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Card from "../../../components/Cards";
import Layout from "../../../components/Layout";
import Map from "../../../components/Map";
import Marked from "../../../components/Map/Marker";
import SEO from "../../../components/SEO";
import requests from "../../../services/requests";
import DriverFreightDetails from "../components/DriverFreightDetails";
import SubmenuDetail from "../components/SubmenuDetail";
import Tabs from "../components/Tab";

const { io } = require("socket.io-client");
const DriverFreightDetailPage = props => {
    const socket = io("https://realtime.vendemmia.com.br");
    // Código para rodar socket local
    //const socket = io('http://localhost:3000', {transports: ['websocket']});
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [load, setLoad] = useState(false);
    const [entity, setEntity] = useState({});
    const [drivers, setDrivers] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [cordinates, setCordinates] = useState([]);
    const [warnings, setWarnings] = useState([]);
    const [checkpoints, setCheckpoints] = useState({});
    const [viewport, setViewport] = useState({
        // geolocalização da vendemmia Vila Olimpia
        latitude: -23.596048575721927,
        longitude: -46.68536409908897,
        zoom: 15,
    });
    const [events, setEvents] = useState({});
    const listDrivers = () => {
        setLoad(true);
        requests.listUsers({ role: "ROLE_DRIVER" }, 0, 99999).then(list => {
            let options = [];
            list.data.map(driver => {
                return options.push({
                    value: driver.identifier,
                    label: driver.name,
                });
            });

            setDrivers(options);
            setLoad(false);
        });
    };

    const listVehicles = () => {
        setLoad(true);
        requests.listVehicles({}, 0, 99999).then(list => {
            let options = [];
            list.data.map(vehicle => {
                return options.push({
                    value: vehicle.identifier,
                    label: vehicle.licensePlate,
                });
            });
            setVehicles(options);
            setLoad(false);
        });
    };

    const listParents = () => {
        setLoad(true);
        requests.listVehicles({}, 0, 99999).then(list => {
            let options = [];
            list.data.map(vehicle => {
                return options.push({
                    value: vehicle.identifier,
                    label: vehicle.licensePlate,
                });
            });

            setVehicles(options);
            setLoad(false);
        });
    };

    const getEntity = () => {
        setLoad(true);
        requests.showDriverFreight(props["*"]).then(data => {
            setEntity(data);

            if (data?.latitudeStartingFrom != null && data?.longitudeStartingFrom != null) {
                setViewport({
                    latitude: data?.latitudeStartingFrom,
                    longitude: data?.longitudeStartingFrom,
                    zoom: 15,
                });
            }

            setLoad(false);
        });
    };

    const listCheckpoints = () => {
        requests.showCheckpointsDriverFreight(props["*"]).then(data => {
            setCheckpoints(data);
        });
    };

    const listEvents = () => {
        requests.showEventsDriverFreight(props["*"]).then(data => {
            setEvents(data);
        });
    };

    useEffect(() => {
        getEntity();
        listDrivers();
        listVehicles();
        listCheckpoints();
        listEvents();
        listParents();
    }, []);

    useEffect(() => {
        if (checkpoints?.length > 0) {
            checkpoints.map(point => {
                if (point.speedFormatted > 89) {
                    warnings.push({
                        longitude: point.longitude,
                        latitude: point.latitude,
                        time: point.dateFormatted,
                        speed: point.speedFormatted,
                        type: "high-speed",
                    });
                } else if (point.speedFormatted < 18) {
                    warnings.push({
                        longitude: point.longitude,
                        latitude: point.latitude,
                        time: point.dateFormatted,
                        speed: point.speedFormatted,
                        type: "low-speed",
                    });
                } else {
                    warnings.push({
                        longitude: point.longitude,
                        latitude: point.latitude,
                        time: point.dateFormatted,
                        speed: point.speedFormatted,
                        type: "checkpoint",
                    });
                }
            });

            setCordinates(checkpoints);
            let lat = (checkpoints[0].latitude + checkpoints[checkpoints.length - 1].latitude) / 2;
            let long = (checkpoints[0].longitude + checkpoints[checkpoints.length - 1].longitude) / 2;

            if (entity.status === "TRAVELLING") {
                setViewport({
                    latitude: checkpoints[0].latitude,
                    longitude: checkpoints[0].longitude,
                    zoom: 12,
                });
            } else {
                setViewport({
                    latitude: lat,
                    longitude: long,
                    zoom: 7,
                });
            }

            let aux = [];

            if (entity?.geolocationReadable != null) {
                entity.geolocationReadable.map(pts => {
                    if (pts?.latitude != null && pts?.longitude != null) {
                        return aux.push([pts.longitude, pts.latitude]);
                    }
                });
            }
        }

        if (events?.length > 0) {
            events.map(event => {
                if (!event.latitude) {
                    return;
                }
                if (event?.modality?.description === "LOG") {
                    return;
                }
                return warnings.push({
                    longitude: event?.longitude,
                    latitude: event?.latitude,
                    type: event?.modality?.description === "MOVING" ? "moving" : "stoped",
                });
            });
        }
    }, [entity, checkpoints, events]);

    useEffect(() => {
        socket.on("connect", () => {
            setIsConnected(true);
        });

        socket.on("position", raw => {
            if (raw.length === 0) {
                return;
            }
            console.log(raw);
            if (entity?.identifier === raw?.trip) {
                let type = "checkpoint";

                const aux = [
                    {
                        longitude: raw?.longitude,
                        latitude: raw?.latitude,
                    },
                ].concat(cordinates);

                if (raw?.speedFormatted > 89) {
                    type = "high-speed";
                } else if (raw?.speedFormatted < 18) {
                    type = "low-speed";
                }

                warnings.push({
                    longitude: raw?.longitude,
                    latitude: raw?.latitude,
                    time: raw?.dateFormatted,
                    speed: raw?.speedFormatted,
                    type: type,
                });

                setCordinates(aux);

                setViewport({
                    latitude: raw?.latitude,
                    longitude: raw?.longitude,
                    zoom: 15,
                });
            }

            return;
        });

        socket.on("disconnect", () => {
            setIsConnected(false);
        });

        return () => {
            socket.off("connect");
            socket.off("disconnect");
            socket.off("position");
        };
    });

    return (
        <Layout>
            {load ? (
                <div className="flex flex-col items-center">
                    <ClipLoader size={20} loading={load} />
                </div>
            ) : (
                <>
                    <SEO title={`Viagem ${entity.code}`} />
                    <SubmenuDetail code={entity.code} prevPath={props.location?.state?.prevPath} />

                    <div className="p-4 flex flex-wrap">
                        <div className="w-1/2 lg:w-2/5 lg:pr-2 xs:pr-0 lg:mb-0 xs:mb-4">
                            <Card className="h-full">
                                <DriverFreightDetails
                                    driverFreight={entity}
                                    vehicles={vehicles}
                                    drivers={drivers}
                                    getEntity={getEntity}
                                />
                            </Card>
                        </div>
                        <div className=" w-1/2 lg:w-3/5 lg:mb-0 xs:mb-4">
                            <Card className="h-full">
                                {entity?.trajectories?.length > 0 ? (
                                    <div className="w-full h-full">
                                        <Map
                                            viewport={viewport}
                                            setViewport={setViewport}
                                            style={{ width: "100%", height: "100%" }}>
                                            {cordinates?.length > 0 && (
                                                <>
                                                    {warnings?.length > 0 &&
                                                        warnings.map((pin, key) => (
                                                            <Marked
                                                                key={key}
                                                                latitude={pin.latitude}
                                                                longitude={pin.longitude}
                                                                data={pin}
                                                                type={pin.type}
                                                            />
                                                        ))}

                                                    {cordinates?.length > 0 && (
                                                        <Marked
                                                            latitude={cordinates[0].latitude}
                                                            longitude={cordinates[0].longitude}
                                                            type="car"
                                                            color={"red"}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </Map>
                                    </div>
                                ) : (
                                    <div className="flex flex-col items-center">
                                        <ClipLoader size={20} loading={load} />
                                    </div>
                                )}
                            </Card>
                        </div>
                    </div>

                    <div className="px-4 pb-4 flex flex-wrap">
                        <Tabs driverFreight={entity} checkpoints={checkpoints} events={events} setViewport={setViewport} />
                    </div>
                </>
            )}
        </Layout>
    );
};

export default DriverFreightDetailPage;
